let privates = new WeakMap();

if (typeof DEVELOPMENT === 'undefined') {
	// DEVELOPMENT should be defined by webpack on build-process.
	// However, when code is used by vueJS, this constant is missing
	window.DEVELOPMENT = false;
}
/**
* @mixin
* @property List Array<T>
* @method IsCacheValid
* @method Refresh
* @method _listObjectType
* @method _buildList
* @method _convertTypes
* @method _createInstance
* @method _sortHandler
 *
*/
export const ApiTraitList = <InstanceType:Object>(superclass) => class extends superclass {
	constructor(...args) {
		super(...args);

		if (!(this instanceof ApiObject)) {
			throw 'ApiTraitList is only intended for an ApiObject';
		}

		this.List = [];

		if (!privates.has(this)) {
			privates.set(this, {});
		}

		let priv = privates.get(this);

		priv.ApiListTrait = {
			cacheTime: 10 * 60 * 1000, // 10 min
			validTill: null,
			force: false,
			currentRefreshPromise:null,
		};


	}

	get useCache():Boolean {
		return privates.get(this).ApiListTrait.force === false;
	}

	set useCache(value: Boolean) {
		privates.get(this).ApiListTrait.force = !value;
	}

	get IsCacheValid() {
		let till = privates.get(this).ApiListTrait.validTill;
		return !!(till && till > new Date());
	}


	Invalidate() {
		if (super.Invalidate) {
			super.Invalidate();
		}
		privates.get(this).ApiListTrait.currentRefreshPromise = null;
		privates.get(this).ApiListTrait.validTill = null;
		$.observable(this.List).refresh([]);
	}

	Refresh(force, options = {}) {
		let that = this;
		let dfd = $.Deferred();
		let priv = privates.get(this);

		if (force === undefined) {
			force = priv.ApiListTrait.force;
		}

		let lastPromise = priv.ApiListTrait.currentRefreshPromise;
		if (lastPromise && lastPromise.state() == 'pending') {
			return lastPromise;
		}

		// if this is used in a project object, make sure 
		// that the project has not changed in between request start and end
		let projectID = null;

		if ('ProjectID' in this) {
			projectID = this.ProjectID;
		}
		let currentPromise = dfd.promise();
		priv.ApiListTrait.currentRefreshPromise = currentPromise;

		if (!this.IsCacheValid || force) {
			// data is stale or request was forced
			API.Get(this.URL, options).done((data) => {
				if ((priv.ApiListTrait.currentRefreshPromise === currentPromise) && (projectID === null || projectID == that.ProjectID)) {
					data = that._convertResult(data);
					let list = [];
					if (data instanceof Array) {
						list = this._buildList(data);
						let now = new Date();

						// update valid Till
						priv.ApiListTrait.validTill = new Date(now.getTime() + priv.ApiListTrait.cacheTime);

						$.observable(that.List).refresh(list);
						this._triggerEvent('List');
					} else {
						dfd.reject('Server responded with unknown data type');
					}

					dfd.resolve(list);
				}
			}).fail(function (error, jqXHR, textStatus, errorThrown) {
				dfd.reject(error, jqXHR, textStatus, errorThrown);
			});

		} else {
			// Daten noch aktuell
			dfd.resolve();
		}
		return priv.ApiListTrait.currentRefreshPromise;
	}

	CloneList() {
		return this.List.slice();
	}
	AddToList(item, verifyProject) {
		if (verifyProject && !this._projectMatches(item)) {
			return;
		}


		let list = this.List.slice();
		list.push(item);
		this._sortList(list, this._sortHandler);

		$.observable(this.List).refresh(list);

		this._triggerEvent('List');
	}

	RemoveFromList(item, verifyProject) {
		if (verifyProject && !this._projectMatches(item)) {
			return;
		}

		let list = this.List;

		for(var i = 0; i < list.length; i++) {
			if (list[i].ID == item.ID) {
				$.observable(list).remove(i);
				break;
			}
		}

		this._triggerEvent('List');
	}


    Get(id):JQueryPromise<InstanceType> {
        let dfd = $.Deferred();

        let instance = this._createInstance({});
        instance.ID = id;
        $.when(instance.Refresh())
            .done(dfd.resolve(instance))
            .fail((...errorArgs) => dfd.reject(...errorArgs));

        return dfd.promise();
    }
	get _listObjectType():Class<InstanceType> {
		return Object;
	}

	get _sortHandler() {
		return () => 0;
	}

	_createInstance(data) {
		let instance = new this._listObjectType;

		if (instance instanceof ApiDataObject) {
			try {
				instance._suppressEvents = true;
				instance._merge(data);
			} finally {
				instance._suppressEvents = false;
			}
		} else {
			$.observable(instance).setPropertyR(this._convertTypes(data));
		}


		return instance;
	}

	_buildList(data) {
		let list = [];
		let that = this;

		data.forEach((entry) => {
			let item = that._createInstance(entry);
			list.push(item);
		});

		//list.sort(that._sortHandler);
		that._sortList(list, that._sortHandler);

		return list;
	}

	_sortList(list, sortHandler) {
		if (!sortHandler) {
			return;
		}

		list.sort(sortHandler);
	}

	_convertTypes(data) {
		return data;
	}

	_convertResult(data) {
		return data;
	}
};

/**
* @mixin ApiTraitUpdate
*/
export const ApiTraitUpdate = (superclass) => class extends superclass {
	constructor(...params) {
		super(...params);

		if (!(this instanceof ApiObject)) {
			throw 'ApiTraitUpdate is only intended for an ApiObject';
		}
	}

	Update(postdata = null, options = {}) {
		let that = this;
		let dfd = $.Deferred();
		let isUpdate = (this.ID === undefined) || (!!(this.ID));

		if (!postdata) {
			// TODO: create better approach
			postdata = $.extend(true, {},this);
		}

		$.when(API.Post(this.URL, this._preparePostData(postdata),options)).done(function(data) {
			if (!isUpdate && data) {
				// new object - get ID from returned URL
				let url = data || '';

				let pos = url.lastIndexOf('/');

				if (pos > 0) {
					$.observable(that).setProperty('ID', url.substring(pos+1));
				}
			}

			if ((this.ID === undefined) || that.ID) {
				$.when(that.Refresh()).done(function () {

					dfd.resolve(that);
				}).fail(function (error, jqXHR, textStatus, errorThrown) {
					dfd.reject(error, jqXHR, textStatus, errorThrown);
				}).always(function() {
					if (!isUpdate && data) {
						that._triggerEvent('Added');
					} else {
						that._triggerEvent('Updated');
					}
				});
			} else {
				dfd.reject('Server did not respond with objectID', null, null, null);
			}
		}).fail(function(error, jqXHR, textStatus, errorThrown) {
			dfd.reject(error, jqXHR, textStatus, errorThrown);
		});

		return dfd.promise();
	}


	_preparePostData(data) {
		return data;
	}
};

/**
* @mixin ApiTraitDelete
*/
export const ApiTraitDelete = (superclass) => class extends superclass {
	constructor(...params) {
		super(...params);

		if (!(this instanceof ApiObject)) {
			throw 'ApiTraitDelete is only intended for an ApiObject';
		}
	}
	Delete() {
		let dfd = $.Deferred();
		let that = this;

		$.when(API.Delete(this.URL)).done(function() {
			that._triggerEvent('Deleted');
			dfd.resolve(that);
		}).fail(function(error, jqXHR, textStatus, errorThrown) {
			dfd.reject(error, jqXHR, textStatus, errorThrown);
		});

		return dfd.promise();
	}
};

export const ApiTraitTranslation = (superclass) => class extends superclass {
	constructor(...params) {
		super(...params);

		if (!(this instanceof ApiProjectObject)) {
			throw 'ApiTraitTranslation is only intended for an ApiProjectObject';
		}

		this.Translations = [];
		// if (!privates.has(this)) {
		// 	privates.set(this, {});
		// }
		//
		// let priv = privates.get(this);
		//
		// priv.ApiTranslationTrait = {
		// 	Translations: [],
		// };
	}

	get ProjectID() {
		return super.ProjectID;
	}
	set ProjectID(pID) {
		let currentID = super.ProjectID;
		super.ProjectID=pID;

		if ((currentID != pID) && (pID !== null)){
			// ID has changed
			this._addTranslations();
		}
	}

	_merge(data) {
		let sp = this._suppressEvents;

		try {
			this._suppressEvents = true;

			super._merge(data);
			this._addTranslations();

			this._triggerEvent('Refreshed', true);
		} finally {
			this._suppressEvents = sp;
		}


	}

	_addTranslations() {
		let project = this.ProjectObject;

		if (project && project.Languages) {
			let existingTranslations = this.Translations.map((t) => t.LanguageID);

			project.Languages.forEach((lang) => {
				if ($.inArray(lang.ID, existingTranslations) === -1) {
					$.observable(this.Translations).insert(this._createTranslationItem(lang));
				}
			});

		}
	}
	_createTranslationItem(language) {
		let item = {
			LanguageID: language.ID,
			Language: language.Name,
			Text: null
		};


		return item;
	}
};

export class DataObject {

    CloneData() {
        return this._convertTypes(JSON.parse(JSON.stringify(this)));
    }
    CloneObject() {
        return new this(this.CloneData());
    }
    _merge(data) {
        $.observable(this).setPropertyR(this._convertTypes(data));
    }

    _convertTypes(data) {
        return data;
    }
}

// base for all API Stuff
export class ApiObject {

	constructor(eventNamespace: String) {
		// this._suppressEvents =  false;

		if (!privates.has(this)) {
			privates.set(this, {});
		}

		let priv = privates.get(this);

		priv.ApiObject = {
			eventNamespace: eventNamespace,
			suppressEvents: false,
		};
	}

	get URL() {
		return API.URL;
	}

	get _eventNamespace() {
		return privates.get(this).ApiObject.eventNamespace;
	}

	get _suppressEvents() {
		return privates.get(this).ApiObject.suppressEvents;
	}

	set _suppressEvents(flag) {
		privates.get(this).ApiObject.suppressEvents  = flag;
	}

	_triggerEvent(eventType, force, ...params) {
		let documentParams = [this].concat(params);

		if (this._eventNamespace == null) {
			return;
		}

		if (!this._suppressEvents || force) {

			let eventName = `${this._eventNamespace}.${eventType}`;

			if (DEVELOPMENT) {
				console.log(eventName);
			}

			$(this).trigger(eventType, params);
			$(document).trigger(eventName, documentParams);

		}
	}

	_projectMatches(item) {

		if (!('ProjectID' in this)) {
			return true;
		}

		if (!('ProjectID' in item)) {
			return true;
		}

		let projectID = this.ProjectID;
		if (projectID == null) {
			projectID = API && API.Projects ? API.Projects.CurrentProjectID:null;
		}

		return item.ProjectID == projectID;
	}
}

// export class ApiMergeObject extends ApiObject {
//
// }
// base class for all Dataobjects providing basic Update / Refresh functionality
export class ApiDataObject extends ApiObject {

	constructor(eventNamespace: String) {
		super(eventNamespace);

		this.ID = null;
	}



	Refresh(options = {}) {
		let that = this;
		let dfd = $.Deferred();

		if (!this.ID && this.ID !== undefined) {
			dfd.resolve(this);
			return dfd.promise();
		}

		$.when(API.Get(this.URL, options)).done(function(data) {

			if (data instanceof Object) {

				delete data.ID;

				that._merge(data);
				dfd.resolve(that);
			} else {
				dfd.reject('Server responded with unknown data type');
			}
		}).fail((...errorArgs) => {
			dfd.reject(...errorArgs);
		});

		return dfd.promise();
	}



	CloneData() {
		return this._convertTypes(JSON.parse(JSON.stringify(this)));
	}
	CloneObject() {


		//let type = this.constructor.name
		//let newInstance = new type();
		//let newInstance = Object.create(Object.getPrototypeOf(this));
		let constructor = this.constructor;

		let newInstance = new constructor(this._eventNamespace);

		try {
			newInstance._suppressEvents = true;

			if (('ProjectID' in this) && ('ProjectID' in newInstance)) {
				if (privates.get(this) && privates.get(this).ApiProjectObject.projectID) {
					newInstance.ProjectID = privates.get(this).ApiProjectObject.projectID;
				}
			}

			newInstance._merge(this.CloneData());
		} finally {
			newInstance._suppressEvents = false;
		}

		return newInstance;
	}

	_merge(data) {
		$.observable(this).setPropertyR(this._convertTypes(data));
		this._triggerEvent('Refreshed');
	}

	_convertTypes(data) {
		return data;
	}

	get _verifyID() {
		return true;
	}
}

// dataobject specific to a project with support functions
export class ApiProjectObject extends ApiDataObject {

	/**
	 *
	 * @param project   integer | Project
	 */
	constructor(eventNamespace: String) {
		super(eventNamespace);

		if (!privates.has(this)) {
			privates.set(this,{});
		}

		let priv = privates.get(this);

		priv.ApiProjectObject = {
			projectID: null,
		};
	}

	get Project() {
		let projectID = privates.get(this).ApiProjectObject.projectID;

		if (!API.Projects || !projectID) {
			return null;
		}

		return API.Projects.GetObject(projectID);
	}

	get ProjectObject() {
		let projectID = privates.get(this).ApiProjectObject.projectID;

		if (!API.Projects) {
			return null;
		}

		if (!projectID) {
			projectID = API.Projects.CurrentProjectID;
		}

		return API.Projects.GetObject(projectID);
	}
	get ProjectID() {
		//return  privates.get(this).ApiProjectObject.projectID;

		let projectID = privates.get(this).ApiProjectObject.projectID;

		if (!projectID) {
			projectID = API.Projects.CurrentProjectID;
			if (DEVELOPMENT) {
				console.log(`Call to Unspecific Project-API ${this.constructor.name}`);
				if (console.trace) {
          console.trace();
        }
			}
		}

		return projectID;
	}

	set ProjectID(value) {
		privates.get(this).ApiProjectObject.projectID = value;
	}

	get URL() {
		return this.ProjectURL;
	}

	get ProjectURL() {
		let projectID = privates.get(this).ApiProjectObject.projectID;

		if (!projectID) {
			projectID = API && API.Projects ? API.Projects.CurrentProjectID:null;

			if (DEVELOPMENT) {
				console.log(`Call to Unspecific Project-API ${this.constructor.name}`);
				if (console.trace) {
          console.trace();
        }
			}
		}

		return `${super.URL}/Projects/${projectID}`;
	}

	CloneObject() {
		let clone = super.CloneObject();

		clone.ProjectID = privates.get(this).ApiProjectObject.projectID;
		return clone;
	}
}


// base class for "Controllers" like lists and stuff
export class ApiController extends ApiObject {
	constructor(eventNamespace: String) {
		super(eventNamespace)

	}




	Init() {

		let dtd = $.Deferred();
		let initDeferreds = [];

		for (let property in this) {
			// noinspection JSUnfilteredForInLoop
			if (this[property] instanceof Object && this[property].Init instanceof Function) {
				initDeferreds.push(this[property].Init());
			}
		}

		$.when.apply(null, initDeferreds).always(function() {
			// resolve Promise
			dtd.resolve();
		});



		return dtd.promise();
	}

	Invalidate() {
		let dtd = $.Deferred();

		let invalidateDeferreds = [];

		for (let property in this) {
			// noinspection JSUnfilteredForInLoop
			if (this[property] instanceof Object && this[property].Invalidate instanceof Function) {
				invalidateDeferreds.push(this[property].Invalidate());
			}
		}

		$.when.apply(null, invalidateDeferreds).always(function() {
			// Promise einlösen
			dtd.resolve();
		});

		return dtd.promise();
	};

}

export class ApiProjectController<T> extends ApiController {

	constructor(project, eventNamespace: String) {
		super(eventNamespace);

		if (!privates.has(this)) {
			privates.set(this,{});
		}

		let priv = privates.get(this);

		priv.ApiProjectController = {
			project: project,
			projectID: project && project.ID ? project.ID:null,
		};
	}

	get ProjectID() {
		//return privates.get(this).ApiProjectController.projectID;


		let projectID = privates.get(this).ApiProjectController.projectID;

		if (!projectID) {
			projectID = API && API.Projects ? API.Projects.CurrentProjectID:null;
			if (DEVELOPMENT) {
				console.log(`Call to Unspecific Project-API ${this.constructor.name}`);
				if (console.trace) {
          console.trace();
        }
			}
		}

		return projectID;
	}

	// FIXME: should this behave analogue to ApiProjectObject and return null if no specific project has been assigned to this controller? The current behaviour should be available as get ProjectObject?
	get Project() {
		let project =privates.get(this).ApiProjectController.project;

		if (!project) {
			project = API.Projects ? API.Projects.CurrentProject:null;
		}

		return project;
	}

	/**
	 * Returns the Project this Controller is associated with
	 * if the controller instance is not bound to a specific object, the current project will be returned.
	 *
	 * @returns Project
	 */
	get ProjectObject() {
		let project =privates.get(this).ApiProjectController.project;

		if (!project) {
			project = API.Projects ? API.Projects.CurrentProject:null;
		}

		return project;
	}

	/**
	 * Returns the Project this controller is associated with.
	 * If the controller is not bound to a specific project, null will be returned.
	 *
	 * This might be  a temporary stopgap measure until we can rewrite the API and align behavior between ApiProjectController and ApiProjectObject
	 *
	 * @returns ?Project
	 */
	get AssociatedProject() {
		return privates.get(this).ApiProjectController.project || null;
	}

	get URL() {
		return `${this.BaseProjectURL}`;
	}

	get BaseProjectURL() {
        let projectID = privates.get(this).ApiProjectController.projectID;

        if (!projectID) {
            projectID = API.Projects && API.Projects.CurrentProject ? API.Projects.CurrentProject.ID : null;

            if (DEVELOPMENT) {
                console.log(`Call to Unspecific Project-API ${this.constructor.name}`);
                if (console.trace) {
                  console.trace();
                }
            }
        }

        return `${API.URL}/Projects/${projectID}`;
	}
}

export class ApiAdminController extends ApiProjectController {
	get URL() {
		return `${super.URL}/Admin`;
	}
}

let API = {};

API.PathName = typeof location !== 'undefined' ? location.pathname.replace(/[^\\\/]*$/, '') : '';
API.Demo = false;
API.prototype = {};
API.cacheTime = 10 * 60 * 1000;	// 10 min
API.URL = API.PathName + 'API';
API.Version = '1.0';
API.Token = '';
API.TokenHash = '';
API.Initialized = $.Deferred();

API.Initialize = function(token) {
	var dtd = $.Deferred();
	var initDeferreds = [];

    $.ajaxSetup({
        beforeSend:function(jqXHR) {
            var now = new Date();

            // CSRF-Token setzen
            jqXHR.setRequestHeader('X-CSRF-Token',token);
            jqXHR.setRequestHeader('X-API-Version', API.Version);
            jqXHR.setRequestHeader('Accept','application/json');
            jqXHR.setRequestHeader('X-TimeZone-Offset', now.stdTimezoneOffset());
            jqXHR.setRequestHeader('Accept-Language', Globalize.culture().name);


            //jqXHR.overrideMimeType('application/json');
        }
    });

	for (let property in this) {
		if (this[property] instanceof Object && this[property].Init instanceof Function) {
			initDeferreds.push(this[property].Init());
		}
	}

	$.when.apply(null, initDeferreds).always(function() {
		// Promise einlösen
		API.Initialized.resolve();
		dtd.resolve();
	});



	return dtd.promise();
};

API.Invalidate = function() {
	var dtd = $.Deferred();
	var invalidateDeferreds = [];
	for (var property in this) {
		if (this[property] instanceof Object && this[property].Invalidate instanceof Function) {
			invalidateDeferreds.push(this[property].Invalidate());
		}
	}

	$.when.apply(null, invalidateDeferreds).always(function() {
		// Promise einlösen
		dtd.resolve();
	});

	return dtd.promise();
};

API.Get = function(url, options) {
    if (!options) {
        options ={};
    }

	return this.Ajax(
        $.extend(options,{
		    url: url,
		    type: 'GET',
		    async: true,
	    })
    );
};

API.Delete= function(url, data, options) {
    if (!options) {
        options ={};
    }

	return this.Post(url + '?_method=DELETE',null, options);
};

API.Post = function(url, data, options) {
    if (!options) {
        options = {};
    }
	return this.Ajax(
        $.extend(
            options,
            {
                url: url,
                type: 'POST',
                data: data === null ? null : JSON.stringify(data),
                contentType: 'application/json',
                async: true
            }
        )
    );
};

API.Ajax = function(options) {
	var dtd = $.Deferred();

	$.extend(options, {
		success: function(data, textStatus, jqXHR) {
			dtd.resolve(data, textStatus, jqXHR);
		},
		error: function(jqXHR, textStatus, errorThrown) {
            // errors processing
			var errorMessage = null ; // $._('An errors occured processing your request.');
			var errorOccurred = 'client';

			switch(Math.floor(jqXHR.status / 100)) {
				case 4:
					switch(jqXHR.status) {
						case 401:
							errorMessage = $._('Your authentication to the server expired. Please reload Digital Purchase Order in your browser an log in again.');
							break;
						case 403:
							errorMessage = $._('You\'re not authorized to access this function.');
							break;
                        case 404:
                            errorMessage = $._('The resource you\'re trying to access could not be found.');
                            break;
					}
					break;
				case 5:
					errorOccurred = 'server';
					switch(jqXHR.status) {
						case 503:
							$(document).trigger('API.Service.Unavailable');
							errorMessage = $._('Service is currently not available due to maintenance.');
							break;
                        case 504:
                            $(document).trigger('API.Service.Unavailable');
                            errorMessage = $._('Service is currently not available due to network issues');
					}
					break;
			}

			var errors = null;

			try {
				errors = JSON.parse(jqXHR.responseText);

				if (errors && !$.isArray(errors)) {
					errors = [];
				}
			} catch(e) {
			}

			let errorID = null;
			let errorData = null;
			if (errors && (errors.length > 0)) {
				errorMessage = errors[0].Message;

				if (errors[0].ErrorID) {
					errorID = errors[0].ErrorID;
				}

				if (errors[0].Data) {
					errorData = errors[0].Data;
				}
			} else if (!errorMessage || errorMessage.length == 0) {
				switch(errorOccurred) {
					case 'server':
						errorMessage = $._('Server errors occurred processing your request.');
						break;
					default:
						errorMessage = $._('An errors occurred processing your request.');
						break;
				}
			}

			dtd.reject(errorMessage, jqXHR, textStatus, errorThrown, errorID, errorData, errors);
		}
	});

	// Ajax Aufruf starten
	$.ajax(options);

	return dtd.promise();
};

export { API };
